<template>
  <Top />
  <div class="nav">
    <div class="nav_left">
      <div class="left_jian" @click="ret_plan">
        <img src="@/assets/img/left_2.png" alt="" />
      </div>
      <div class="word">{{ lang.v.code }}</div>
    </div>
    <div class="num_task">{{ lang.v.Remaining }} {{ userinfo.codeNum }}</div>
  </div>
  <div class="record">
    <div class="top">
      <div class="top_left">
        <div class="reward_logo">
          <img src="@/assets/img/exchange.png" alt="" />
        </div>
        <div class="reward_title">{{ lang.v.Exchange }}</div>
      </div>
      <div class="refresh" @click="refresh">
        <img :style="imageStyle" src="@/assets/img/refresh-org.png" alt="" />
      </div>
    </div>
    <div class="lists">
      <div class="list_head" v-if="lists == 0 ? false : true">
        <div class="head">{{ lang.v.Redemption }}</div>
        <div class="head">{{ lang.v.Duration }}</div>
        <div class="head">{{ lang.v.Period2 }}</div>
        <div class="head">{{ lang.v.State }}</div>
        <div class="head">{{ lang.v.Function }}</div>
      </div>
      <div class="list_main" v-for="list in lists" :key="list.id">
        <div class="head">{{ list.code }}</div>
        <div class="head">{{ list.duration }} {{ lang.v.days }}</div>
        <div class="head">{{ list.expireDate }}</div>
        <div class="head" :innerText="list.status == 0 ? nouse : Used"></div>
        <div class="head">
          <div
            @click="copy(list)"
            class="copy-button"
            style="
              width: 0.5rem;
              height: 0.2rem;
              background-color: rgba(237, 147, 30, 1);
              border-radius: 1.66rem;
              color: #ffffff;
              line-height: 0.2rem;
              text-align: center;
              margin-top: 0.1rem;
            "
          >
            {{ lang.v.Copy }}
          </div>
        </div>
      </div>
      <div class="no_text" v-if="no_more">{{ lang.v.none }}</div>
      <van-loading size="0.24rem" v-if="loading">{{ lang.v.load }}</van-loading>
      <div class="list_more" v-if="more" @click="loadMore">
        <div class="text">{{ lang.v.loadmore }}</div>
        <div class="more_logo">
          <img src="~@/assets/img/down-org.png" alt="" />
        </div>
      </div>
    </div>
  </div>
  <tabb></tabb>
</template>
<script setup>
import { computed, onMounted, reactive, ref } from 'vue';
import router from '@/router';
import { code_num } from '@/api/fetcher';
import message from '@/utils/message';
import { lang } from '@/lang';
import Top from '@/components/Top/top.vue';
import Clipboard from 'clipboard';
const ret_plan = () => {
  router.go(-1);
};
const nouse = lang.v.nouse;
const Used = lang.v.Used;
const more = ref(false);
const no_more = ref(false);
const pages = reactive({
  page: 1,
  limit: 6,
});
const userinfo = ref(JSON.parse(localStorage.getItem('userInfo')));
// 刷新按钮
const imageStyle = ref({
  transform: 'rotate(0deg)', // 初始旋转角度为0度
  transition: 'transform 0.5s ease', // 添加过渡效果
});
const refresh = async () => {
  const currentRotation =
    parseInt(imageStyle.value.transform.slice(7), 10) || 0; // 获取当前旋转角度
  const rotationAngle = currentRotation + 360; // 计算下一次旋转角度
  // 更新图片的样式，应用旋转效果
  imageStyle.value.transform = `rotate(${rotationAngle}deg)`;
  lists.value = [];
  setTimeout(async () => {
    await code_num({
      page: 1,
      limit: pages.limit,
    }).then(async res => {
      const data = res;
      if (data.list.length < pages.limit) {
        more.value = false;
        if (data.list.length == 0) {
          no_more.value = true;
        }
      } else {
        more.value = true;
      }
      lists.value = [...data.list];
    });
  }, 500);
};
const loading = ref(false);
const loadMore = async () => {
  loading.value = true;
  setTimeout(async () => {
    await code_num({
      page: pages.page + 1,
      limit: pages.limit,
    }).then(res => {
      loading.value = false;
      const data = res.data.data;
      if (data.list.length < pages.limit) {
        more.value = false;
      } else {
        pages.page++;
        more.value = true;
      }
      const temp = [];
      data.list.forEach(v => {
        temp.push(v);
      });
      lists.value = [...lists.value, ...temp];
    });
  }, 500);
};
// 复制功能
const success4 = computed(() => lang.v.success4);
const copy = list => {
  const textToCopy = list.code;
  const clipboard = new Clipboard('.copy-button', {
    text: () => textToCopy,
  });
  // 监听复制成功事件
  clipboard.on('success', e => {
    message.success(success4);
    e.clearSelection();
  });

  // 监听复制失败事件
  clipboard.on('error', e => {
    message.error('err', e);
  });
};

const lists = ref([]);

onMounted(async () => {
  await code_num({
    page: 1,
    limit: pages.limit,
  }).then(async res => {
    const data = res;
    if (data.list.length < pages.limit) {
      more.value = false;
      if (data.list.length == 0) {
        no_more.value = true;
      }
    } else {
      more.value = true;
    }
    lists.value = [...data.list];
  });
});
</script>
<style lang="less" scoped>
:deep(.van-loading) {
  text-align: center;
}

.nav {
  width: 100%;
  height: 0.2rem;
  display: flex;
  margin-top: 0.16rem;
  justify-content: space-between;

  .nav_left {
    display: flex;
    height: 0.2rem;
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 0.2rem;
    margin-left: 0.14rem;

    .left_jian {
      width: 0.2rem;
      height: 0.2rem;

      img {
        width: 100%;
      }
    }

    .word {
      background: linear-gradient(90deg, #f46b45 0%, #eea849 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .num_task {
    height: 0.2rem;
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #858c9f;
    line-height: 0.2rem;
    margin-right: 0.14rem;
  }
}

.record {
  width: 92.53%;
  margin: auto;
  margin-top: 0.16rem;
  background: #ffffff;
  border-radius: 0.06rem;

  .top {
    width: 100%;
    height: 0.2rem;
    display: flex;
    padding-top: 0.14rem;
    justify-content: space-between;

    .top_left {
      display: flex;
      margin-left: 0.14rem;

      .reward_logo {
        width: 0.2rem;
        height: 0.2rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .reward_title {
        height: 0.2rem;
        font-size: 0.16rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: rgba(237, 147, 30, 1);
        line-height: 0.2rem;
        margin-left: 0.06rem;
      }
    }

    .refresh {
      width: 0.2rem;
      height: 0.2rem;
      margin-right: 0.14rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .lists {
    width: 100%;
    overflow-x: auto;
    z-index: 2;
    position: relative;

    .list_head {
      width: 92.5%;
      display: flex;
      height: 0.5rem;
      margin: auto;

      .head {
        width: 1.5rem;
        height: 0.5rem;
        line-height: 0.5rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        flex-shrink: 0;
        color: #858c9f;
        border-bottom: 1px solid rgba(133, 140, 159, 0.2);
      }

      .head:nth-child(2) {
        width: 0.7rem;
      }

      .head:nth-child(3) {
        width: 1.6rem;
      }

      .head:nth-child(4) {
        width: 1rem;
      }

      .head:nth-child(5) {
        width: 0.8rem;
      }
    }

    .list_main {
      width: 92.5%;
      display: flex;
      margin: auto;
      height: 0.4rem;

      .head {
        width: 1.5rem;
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        flex-shrink: 0;
        color: #858c9f;
      }

      .head:nth-child(2) {
        width: 0.7rem;
      }

      .head:nth-child(3) {
        width: 1.6rem;
      }

      .head:nth-child(4) {
        width: 1rem;
      }

      .head:nth-child(5) {
        width: 0.8rem;
      }
    }

    .no_text {
      text-align: center;
      height: 0.2rem;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #858c9f;
      line-height: 0.2rem;
      margin-top: 0.06rem;
      padding-bottom: 0.1rem;
    }

    .list_more {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0.06rem;

      .text {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #444444;
        line-height: 0.2rem;
      }

      .more_logo {
        width: 0.2rem;
        height: 0.2rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
